@import "../../../../styles/variable";
.round-container{
  max-width: rem(1410px);
  padding: rem(32px) rem(24px);
  background-color: #15181B;
  margin: rem(32px) auto;
  &__info{
    min-width: rem(322px);
  }
  .info-title{
    font: $fw-normal rem(20px) $font-poppins-regular;
    line-height: rem(30px);
    color: white;
    img {
      margin-left: rem(9px);
      cursor: pointer;
    }
  }
  .vesting-schedule{
    font: $fw-normal rem(14px) $font-poppins-regular;
    line-height: rem(21px);
    color: #A4A5AF;
    margin: rem(14px) 0 rem(10px);
    &__time{
      color: #E1E2E9;
      img {
        margin-left: rem(4px);
      }
    }
  }
  .unlock-in{
    margin: rem(13px) 0 rem(8px);
    color: #9B8C73;
    font:  $fw-normal rem(18px) $font-CookieRun-Regular;
    line-height: rem(22px);
  }

  .claim-btn{
    font: $fw-bold rem(12px) $font-poppins-regular;
    line-height: rem(18px);
    padding: rem(7px) 0;
    width: rem(167px);
    border-radius: 4px;
    text-align: center;
    background: linear-gradient(180deg, #F9C744 0%, #DF8C00 100%);
    box-shadow: 0px 4px 0px #AE4B12;
    cursor: pointer;
    margin-top: rem(32px);
    color: white
  }
}


@media (max-width:1294px) {
}

@media (max-width: 575px) {
    
}
