@import "../../../../styles/variable";
.table-container{
  width: rem(450px);
  border-radius: rem(4px);
  background-color: #1C2023;

  &__title{
    width: 100%;
    padding: rem(10px) rem(16px);
    background-color: #292929;
    color: #E1E2E9;
    border-radius: 4px 4px 0 0 ;
  }
  &__content{
    padding: rem(21px) rem(15px);

    .content-item{
      display: flex;
      flex-direction: row;
      img{
        margin-right: rem(8px);
      }
      &__title{
        color: #6B7280;
        font:  $fw-normal rem(12px) $font-CookieRun-Regular;
        line-height: rem(18px);
        border-top-left-radius: rem(4px);
        border-top-right-radius: rem(4px);
        
      }
      &__number{
        color: white;
        font:  $fw-normal rem(14px) $font-CookieRun-Regular;
        line-height: rem(21px);
        display: flex;
        flex-direction: row;
        margin-top: rem(4px);
      }
      &__token{
        margin-left: rem(6px);
        color: #E89F01
      }
    }
  }
}

@media (max-width: 575px) {
  .table-container{
    margin-top: rem(30px);
    &__content {
      padding-bottom: 0;
      padding-top: 0;
      .content-item{
        margin: rem(12px) 0 ;
      }

    }
  }
}
