@import "../../../styles/variable";

.ant-modal{
    max-width: unset;
}

.connect-wallet-modal{
        display: flex;
        flex-direction: row;
        justify-content: center;
        // background-color: #0F0D13;
        width: 100% !important;
        height: 100%;
        top: 70px;
        padding-top: 50px;
        
        background-image: url('../../../assets/images/bg-image.png');
        .ant-modal-content {
            border-radius: 4px;
            width: 520px;
            height: fit-content;

        }
        .ant-modal-body{
            padding-bottom: 40px;
        }
        .popup-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: fit-content;
            .ant-tabs-top > .ant-tabs-nav::before {
                border: none;
            }

            .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
                height: 37px;
                align-items: flex-end;
            }

            .ant-tabs-nav {
                padding: rem(20px) rem(46px);
                margin-bottom: 0;
            }

            .ant-tabs-tab{
                margin: 0 30px 0 0;
                font: $fw-normal rem(14px) $font-poppins-regular; 
                line-height: rem(14px);

                &-btn {
                    color:#E1E2E9;
                    opacity: 0.5;
                }
                &-active .ant-tabs-tab-btn {
                    color: #E1E2E9;
                    opacity: 1;
                }
            }
            .ant-tabs-ink-bar {
                background: linear-gradient(90deg,rgba(232,159,1,0),#e89f01 47.4%,rgba(232,159,1,0));
                height: 1px;
            }
            .wallet-icon {
                width: 24px;
                height: 24px;
            }
            .wallet-box-line{
                box-shadow: -6px 8px 5px 1px rgba(0, 0, 0, 0.2);
                height: 1px;
            }
            .title-popup {
                font-size: rem(25px);
                line-height: rem(21px);
                font-family: $font-poppins-bold;
                padding-bottom: rem(20px);
                letter-spacing: -0.05em;
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #201B18;
                -webkit-text-fill-color: $color-white;
                font-weight: $fw-bolder;  
            }

            .offer-info {
                padding-top: 0 !important;
            }

            .chain-title {
                font-size: 14px;
                line-height: 100%;
                margin: 12px 46px;
                color: #e1e2e9;
                @media only screen and (max-width: 600px) {
                    margin: 8px 16px;
                }
            }

            .wallet-connect-btn {
                display: flex;
                background: $color-white;
                max-width: rem(411px);
                height: 48px;
                font-size: 18px;
                box-shadow: inset 0px 3px 0.5px #F2B109;
                border-radius: 6px;
                padding: 12px;
                align-items: center;
                margin: 8px 46px;
                cursor: pointer;
                @media only screen and (max-width: 600px) {
                    margin: 8px 16px;
                }
                .wallet-type {
                    color: $color-gray-4;
                }
                img {
                    display: block;
                    margin-right: 14px;
                    &.wallet-arrow {
                        margin-left: auto;
                        margin-right: 0px;
                    }
                }
            }

            .terra-block {
            margin-top: 34px;
            }
            .terra-btn{
                background-color: #C4C4C4;
                cursor: no-drop;
            }

            .dark-modal {
                @media only screen and (max-width: 769px) {
                    margin: 0 rem(16px) !important;
                    width: 90%;
                    max-width: rem(343px);
                    .wallet-box-line {
                        margin-bottom: 34px;
                    }
                    .popup-content {
                        padding-bottom: rem(4px);
                    }
                    .ant-modal-body {
                        padding: rem(24px) rem(20px);
                    }
                    .wallet-connect-btn {
                        margin: rem(12px) 0;
                    }
                }
            }
        }
    }


@media (max-width: 575px) {
    .connect-wallet-modal {
        // flex-direction: column;
        // height: 100vh;
        .ant-modal-content {
            width: 100%;
        }
        .img-logo{
            width: 100px;
            margin: 30px 10px;
        }
        .popup-content {
            margin-top: 20px;
            margin-left: 0;
            .ant-tabs-nav {
                padding-left: 16px;
            }
            .title-popup {
                margin-left: 16px;
            }
        }
    }
}
