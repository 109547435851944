@import "./styles/variable";
@import "./styles/ultis.scss";
@import "antd/dist/antd.css";
@import "./styles/App.scss";

* {
  margin: 0;
  padding: 0;
}

.font-weight-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

$spacer: 16px;

.mt-1 {
  margin-top: ($spacer * 0.25);
}

.mt-2 {
  margin-top: ($spacer * 0.5);
}

.mt-3 {
  margin-top: ($spacer * 0.75);
}

.mt-4 {
  margin-top: $spacer;
}

.mt-max {
  margin-top: $spacer * 4;
}

.mb-1 {
  margin-top: ($spacer * 0.25);
}

.mb-2 {
  margin-top: ($spacer * 0.5);
}

.mb-3 {
  margin-top: ($spacer * 0.75);
}

.mb-4 {
  margin-top: $spacer;
}

.mb-max {
  margin-bottom: $spacer * 4;
}

.ant-message {
  font-size: to-rem(18px);
  line-height: to-rem(28px);
  font-family: $font-poppins-medium;
  font-weight: 500;
  z-index: 9999999;
  .ant-message-notice-content {
    width: to-rem(332px);
    border-radius: to-rem(8px);
    padding: to-rem(30px) to-rem(32px) !important;
  }
}



.message-icon {
  width: to-rem(20px);
  margin-right: to-rem(18px);
}

input#email.ant-input {
  background: #EEEEEE !important
}
