@import "../../../styles/variable";
 
.box-countdown{
    // width: rem(430px);
    // height: rem(105px);
    display: flex;
    flex-direction: row;
    .time-value {
        font: 400 rem(40px) $font-CookieRun-Regular; 
        color: #E6CEA2;
    }

    .time-text {
        font : 400 rem(12px) $font-poppins-regular;
        color: #9B8C73;  
        text-align: center;
        margin-right: rem(25px);
    }

}

@media (max-width: 575px) {
}
