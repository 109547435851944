@import "../../../styles/variable";
.home-container{
  .my-allocation{
    font: 500 rem(24px) $font-poppins-regular;
    color: white;
    margin-top: rem(64px);
    .ant-switch {
        border: 1px solid #5F3824;
        box-sizing: border-box;
        border-radius: 4px;
        background: linear-gradient(180deg, #3A4442 0%, #3A3A44 100%);
        margin-left: rem(8px);

        &-checked {
            background: #E89F01;
        }
        &-handle{
            top: 1px;
            &::before {
                background: linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%);
                box-shadow: -2px 1px 6px rgba(0, 0, 0, 0.25);
                border-radius: 3px;
            }
        }
    }
  }
}


@media (max-width:1294px) {
}

@media (max-width: 575px) {
    
}
